import cn from 'classnames';
import Shared from './components/Shared';
import UserCard from './components/UserCard/UserCard.css';
import AccountModule from './components/AccountModule/AccountModule.css';
import ProfileControls from './components/ProfileControls/ProfileControls.css';
import Sidebar from './components/Sidebar/Sidebar.css';
import OrderCard from './components/OrderCard/OrderCard.css';
import Traveller from './components/Traveller/Traveller.css';
import TravellerForm from './components/TravellerForm/TravellerForm.css';
import Account from './components/Account/Account.css';
import Link from './components/Link/Link.css';
import AddBooking from './components/AddBooking/AddBooking.css';
var theme = {
    Input: {
        Input: {
            input: Shared.Inputs.input,
            input_filled: Shared.Inputs.input_filled,
            real_input: Shared.Inputs.real_input,
            textField__root: Shared.Inputs.textField__root,
            input_error: Shared.Inputs.input_error,
            input_standard: Shared.Inputs.input_standard,
            hint: Shared.Inputs.hint
        }
    },
    UserCard: {
        UserCard: {
            header_content: UserCard.header_content,
            empty: UserCard.empty,
            avatar: UserCard.avatar,
            edit: UserCard.edit,
            info: UserCard.info,
            name: UserCard.name
        }
    },
    AccountModule: {
        Account: {
            orders__wrapper: AccountModule.orders__wrapper,
            route__title: AccountModule.route__title,
            active__route: AccountModule.active__route,
            homeUserCard: AccountModule.homeUserCard,
            profileControls: AccountModule.profileControls,
            container_mailSettings: AccountModule.container_mailSettings
        }
    },
    ProfileControls: {
        ProfileControls: {
            actions: ProfileControls.actions
        }
    },
    Sidebar: {
        Sidebar: {
            sidebar: Sidebar.sidebar,
            sidebar__item: Sidebar.sidebar__item,
            title: Sidebar.sidebar__title,
            icon: Sidebar.sidebar__icon
        },
        MobileMenu: {
            title: Sidebar.mobileMenu__title
        }
    },
    OrderCard: {
        OrderBooking: {
            root: OrderCard.root,
            type_account: OrderCard.type_account
        },
        OrderCard: {
            buttons: OrderCard.buttons,
            openOrder: cn(Shared.Button.CTN, OrderCard.openOrder)
        },
        DeleteOrderModal: {
            delete_order: OrderCard.delete_order
        },
        PaymentStatus: {
            payload: OrderCard.payload
        },
        OrderPreview: {
            inner: OrderCard.inner,
            city: OrderCard.city,
            main_city: OrderCard.main_city,
            period: OrderCard.period
        }
    },
    Traveller: {
        Traveller: {
            wrapper: Traveller.wrapper,
            header: Traveller.header,
            popup__header: Traveller.popup__header
        }
    },
    TravellerForm: {
        TravellerForm: {
            row: TravellerForm.row,
            add: TravellerForm.add,
            addWrapper: TravellerForm.addWrapper,
            loader: TravellerForm.loader,
            sex: TravellerForm.sex
        }
    },
    Account: {
        Account: {
            account__deleteModal: Shared.Modal.modal
        },
        InfoField: {
            field__name: Account.field__name,
            field__change: Account.field__change
        },
        ConfirmDialog: {
            button_normal: Account.confirmDialog__button_normal,
            button_accent: cn(Shared.Button.CTN, Account.confirmDialog__button_accent),
            button_loader: Account.button_loader
        },
        DeleteAccountDialog: {
            closeButton: cn(Shared.Button.closeIcon, Shared.Button.close__popup, Shared.Modal.closeIcon, Account.deleteAccount__close)
        }
    },
    Link: {
        Link: {
            link: Link.link
        }
    },
    Passenger: {
        Switch: {
            container: Shared.Switch.container,
            element: Shared.Switch.element,
            element_active: Shared.Switch.element_active,
            element_error: Shared.Switch.element_error,
            error: cn(Shared.Switch.error, TravellerForm.switch__error)
        }
    },
    AddBooking: {
        AddBooking: {
            buttonCancel: AddBooking.buttonCancel,
            buttonContinue: cn(Shared.Button.CTN, AddBooking.buttonContinue),
            close: cn(Shared.Button.closeIcon, Shared.Button.close__popup, Shared.Modal.closeIcon),
            spoilerDetails: AddBooking.spoilerDetails
        }
    },
    TicketExample: {
        TicketExample: {
            ticketExample: cn(AddBooking.ticketExample)
        }
    },
    Button: {
        ActionButton: {
            button: Shared.Button.CTN
        }
    },
    Contacts: {
        ResendStyles: {
            back: Account.resend__back
        }
    },
    Modal: {
        Modal: {
            modal_rounded: Shared.Modal.modal_rounded,
            closeIcon: cn(Shared.Button.closeIcon, Shared.Button.close__popup, Shared.Modal.closeIcon),
            modal: Shared.Modal.modal,
            mobile: Shared.Modal.modal,
            closeIcon_mobileFullScreen: Shared.Button.close__fullscreen
        }
    },
    Warnings: {
        WarningsStyles: {
            warning__text: Account.warning__text
        }
    },
    Loyalty: {
        LoyaltyConnect: {
            buttons__wrapper: Account.buttons__wrapper
        }
    }
};
export default theme;
